import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { css } from '@emotion/react';
import './Style.css';
import ReactModal from 'react-modal';

import { MdKeyboardArrowRight, MdOutlineReportGmailerrorred } from 'react-icons/md';
import { TbCircleCheck, TbCoin, TbKey, TbShare } from 'react-icons/tb';
import { TiContacts } from 'react-icons/ti';
import IntlCurrencyInput from 'react-intl-currency-input';
import BeatLoader from 'react-spinners/BeatLoader';
import bankList from '../../../../services/bankList.json';
import { apiV1 } from '../../../../lib/axios';
import { getPixInfo, listContacts, listPix, sendPixFitBank } from '../../../../services/pix.service';
import { sendTransactionMfa } from '../../../../services/mfa.service';
import { useAccount } from '../../../../hooks/useAccount';
import { useAuth } from '../../../../hooks/useAuth';
import { getImage } from '../../../../services/getAssets';
import HistoricoPixRow from '../historico-pix-row/HistoricoPixRow';
import { FilterItemMfaGeneral } from '../../../E-check/style';
import { hideTaxNumber } from '../../../../utils/formatter';
import { identificarTipoChavePix } from '../../../../utils/identificarTipoChavePix';

import moment from 'moment';
import 'moment/locale/pt-br';
import { TransactionType } from '../../../../services/enums';


export default function () {
  const location = useLocation();
  const [valor, setValor] = useState(0);
  const [chave, setChave] = useState('');
  const [response, setResponse] = useState({});

  const [mfaRequired, setMfaRequired] = useState(false);

  const [isInvalidKey, setIsInvalidKey] = useState(false);
  const [recentPixes, setRecentPixes] = useState();
  const [contacts, setContacts] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const [isFavorite, setisFavorite] = useState();
  const [alreadyInFavorites, setAlreadyInFavorites] = useState(false);
  const [receipt, setReceipt] = useState(false);
  const [preReceit, setPreReceipt] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [sendPixLoader, setSendPixLoader] = useState(false);
  const [getInfoLoader, setGetInfoLoader] = useState(false);
  const [historicLoader, setHistoricLoader] = useState(false);

  const { user, getProfile } = useAuth();
  const { avaliableBalance, getBalance } = useAccount();
  const [mfaCode, setMfaCode] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);


  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    display: flex;
  `;

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleClick = useCallback(async () => {
    await sendTransactionMfa();
    setIsDisabled(true);
    setCountdown(40);
  
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }, []);

  const handleChange = (event, value) => {
    event.preventDefault();
    setErrorMessage('');

    setValor(value);
  };

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    setPreReceipt();
    setReceipt();
    setMfaRequired(false);
    setChave('');
    setValor(0);
    setGetInfoLoader(false);
    setSendPixLoader(false);
  }, []);

  async function verifyFavorite(data) {
    const response = await listContacts();

    if (!response?.data?.rows) return;

    const contacts = response.data.rows;

    const alreadyFavorite = contacts.find(
      (item) => item.key === data.PixKeyValue
    );

    setAlreadyInFavorites(alreadyFavorite);
  }

  const getInfos = useCallback(async (chave) => {
    if (sendPixLoader) return;

    if (isTestUser()) {
      return handleTestUser();
    }
  
    if (isInvalidPixKey(chave)) {
      toast.error('Insira a chave pix!');
      setGetInfoLoader(false);
      return;
    }
    setGetInfoLoader(true);

    try {
      const preReceipt = await fetchPixInfo(chave);
      setPreReceipt(preReceipt);
      await verifyFavorite(preReceipt);
      handleSuccess();
    } catch (err) {
      handleError(err);
    }
  }, [sendPixLoader, user]);

  const isTestUser = () => user.taxNumber === '12345678900';

  const handleTestUser = () => {
    setPreReceipt({
      ReceiverBankName: 'Nu Pagamentos S.A',
      ReceiverName: 'Conta Teste',
      ReceiverISPB: '18236120',
      ReceiverBank: '260',
      ReceiverBankBranch: '1',
      ReceiverBankAccount: '1231232',
      ReceiverBankAccountDigit: '3',
      ReceiverAccountType: '1',
      PixKeyType: '1',
      PixKeyValue: '12345678900',
      ReceiverTaxNumber: '12345678900',
    });
    setIsOpenModal(true);
    setGetInfoLoader(false);
    setIsInvalidKey(false);
  };

  const isInvalidPixKey = (chave) => !chave;

  const fetchPixInfo = async (chave) => {
    const response = await getPixInfo(chave, identificarTipoChavePix(chave));
    return response.data.data;
  };

  const handleSuccess = () => {
    setIsOpenModal(true);
    setGetInfoLoader(false);
    setIsInvalidKey(false);
  };
  
  const handleError = () => {
    setGetInfoLoader(false);
    setIsInvalidKey(true);
  };

  const handleSendPixError = (err) => {
    toast.error(err.response.data.message);
  };

  const sendPix = useCallback(async () => {
    if (sendPixLoader) return;
    
    setSendPixLoader(true);
    setReceipt('');

    if (isEmptyMfaCode()) return setSendPixLoader(false);
  
    if (isTestUser()) return handleTestUserTransaction();
  
    const body = createSendPixRequestBody();
  
    try {
      const response = await sendPixFitBank(body);
      if (response) await handleTransactionSuccess(response);
    } catch (err) {
      handleSendPixError(err);
      closeModal();
    } finally {
      resetStateAfterTransaction();
    }
  }, [ sendPixLoader, mfaRequired, valor, avaliableBalance, mfaCode, user, preReceit, isFavorite, bankList, sendPixFitBank, getComprovante, getImage ]);

  const isInvalidAmount = () => {
    if (!valor) {
      setErrorMessage('Digite o valor.');
      return true;
    }
    return false;
  };
  
  const isExceedingBalance = () => {
    if (valor > avaliableBalance) {
      setErrorMessage('O valor excede o saldo disponível.');
      return true;
    }
    return false;
  };

  const isEmptyMfaCode = () => {
    if (!mfaCode.trim()) {
      toast.error('Código MFA não pode estar vazio');
      return true;
    }
    return false;
  };

  const handleTestUserTransaction = () => {
    setResponse({
      toName: 'Teste',
      toBank: '260',
      toTaxNumber: '16440384716',
    });
    setReceipt(true);
  };

  const createSendPixRequestBody = () => {
    console.log("preReceit ", preReceit);
    return {
      value: valor,
      keyType: preReceit.PixKeyType,
      key: preReceit.PixKeyValue,
      isPixFavorito: isFavorite,
      searchProtocol: preReceit.searchProtocol,
      otp: mfaCode,
      receiverInfo: {
        name: preReceit.ReceiverName,
        taxNumber: preReceit.ReceiverTaxNumber,
        bank: preReceit.ReceiverBank
      },
    };
  };

  const handleTransactionSuccess = async (response) => {
    const pixResponseData = response.data.data;

    const transferDateAsDate = moment(pixResponseData.createdAt).toDate();

    setResponse(pixResponseData);
  
    await getComprovante({
      transferDate: transferDateAsDate,
      type: TransactionType.PreDebitPixOut,
      receiverName: pixResponseData.toName,
      receiverTaxNumber: pixResponseData.toTaxNumber,
      pixKey: pixResponseData.toPixKey,
      receiverBank: `${preReceit?.ReceiverBank} - ${getBankName(preReceit.ReceiverBank)}`,
      receiverBankBranch: pixResponseData.toBankBranch,
      receiverBankAccount: pixResponseData.toBankAccount,
      receiverBankAccountDigit: pixResponseData.toBankAccountDigit,
      payerName: user.name,
      payerTaxNumber: user.taxNumber,
      payerBank: `${user.account.bank} - ${getBankName(user.account.bank)}`,
      payerBankBranch: user.account.bankBranch,
      payerBankAccount: user.account.bankAccount,
      payerBankAccountDigit: user.account.bankAccountDigit,
      identifier: pixResponseData.identifier,
      value: valor,
      transactionId: pixResponseData.id,
      url_logo: getImage('logo-preto.png'),
    });
  
    setReceipt(true);
  };

  const getBankName = (bankCode) => {
    const bank = bankList.find((item) => item.code == bankCode);
    return bank ? bank.name : '';
  };

  const resetStateAfterTransaction = () => {
    setSendPixLoader(false);
    setMfaRequired(false);
    setMfaCode(null);
  };

  async function getComprovante(data) {
    await apiV1.post('/voucher/generate', data).then((response) => {
      const res = response.data.data;

      localStorage.removeItem('comprovante');
      localStorage.setItem('comprovante', res.base64);
    });
  }

  const sendMfa = useCallback(async () => {
    if (isInvalidAmount()) return;
    if (isExceedingBalance()) return;
  
    await sendTransactionMfa();
    setMfaRequired(true);
  }, [valor, avaliableBalance]);

  const getInitials = useCallback((name) => {
    const words = name.split(' ');
    if (words.length > 1) {
      return words[0][0] + words[1][0];
    }
    return words[0][0];
  }, []);

  const handleContactOnClick = (item) => {
    if(getInfoLoader) return;

    setAlreadyInFavorites(true);
    getInfos(item.key);
  }

  useEffect(() => {
    const getSentPixs = async () => {
      if (user.taxNumber === '12345678900') {
        setRecentPixes();
        return;
      }
      setHistoricLoader(true);
      const sentPixs = await listPix();
      setRecentPixes(sentPixs.data);
      setHistoricLoader(false);
    };

    const getContacts = async () => {
      const contacts = await listContacts();
      setContacts(contacts.data);
    };

    if (location.state) {
      if (location.state.pixKey) {
        setChave(location.state.pixKey);
        getInfos(location.state.pixKey);
      } else {
        toast.warn('Falha ao tentar enviar PIX para este contato.');
      }
    }

    getContacts();
    getSentPixs();
  }, [location, toast]);

  useEffect(() => {
    if (!avaliableBalance) {
      getBalance();
    }
  }, []);

  return (
    <>
      <div className="transferencia-pix">
        <div className="col-md-10 transferencia-pix-container">
          <div className="header">
            <div className="col-xl-12">
              <label>
                <Link to={'/pagina-principal'}>
                  Pagina inicial
                  <MdKeyboardArrowRight size={20} />
                </Link>
                <Link to={'/pagina-pix'}>
                  Área Pix
                  <MdKeyboardArrowRight size={20} />
                </Link>
                Transferência Pix
              </label>
              <p>Transferência Pix</p>
            </div>
          </div>

          <div className="body">
            <div className="col-md-12">
              <div className="col-md-12 d-flex align-items-end gap-4">
                <div className="col-md-6">
                  <label>Chave Pix</label>
                  <div
                    className={`input-area ${
                      isInvalidKey ? 'invalid-input' : ''
                    }`}
                  >
                    <div className="input-area-icon">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        type="text"
                        value={chave}
                        placeholder="CPF, CNPJ, Celular ou Chave Aleatória"
                        onChange={(e) => {
                          setChave(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex gap-3">
                  <button
                    disabled
                    title="Em Breve"
                    className="btn-contatos-disabled"
                  >
                    <TiContacts size={16} />
                    Contatos
                  </button>
                  <button
                    disabled={getInfoLoader}
                    onClick={() => getInfos(chave)}
                  >
                    {getInfoLoader ? (
                      <BeatLoader
                        color="var(--text-primary)"
                        loading={getInfoLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Prosseguir'
                    )}
                  </button>
                </div>
              </div>
              <label
                className={`error-message ${
                  isInvalidKey ? 'invalid' : 'valid'
                }`}
              >
                <MdOutlineReportGmailerrorred size={14} />
                Chave pix não identificada
              </label>

              <div className="col-md-12 mt-5">
                <h2>Contatos Recentes</h2>
                <div className="d-flex">
                  {contacts &&
                    contacts.map((item, index) => {
                      return (
                        <div
                          className="recent-card"
                          key={index}
                          onClick={() => handleContactOnClick(item) }
                        >
                          <div className="recent-icons">
                            {getInitials(item.name)}
                          </div>
                          <div className="recent-content">
                            <p>{item.name}</p>
                            <span>{item.bank}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <h2>Últimas Transações</h2>

            <div className="historico-area">
              <BeatLoader
                color={'var(--secondary-color)'}
                loading={historicLoader}
                css={override}
                size={20}
              />
              {recentPixes &&
                recentPixes.map((item) => (
                  <HistoricoPixRow
                    key={item.id}
                    data={{
                      name: item.receiverName,
                      taxNumber: item.receiverTaxNumber,
                      date: item.date,
                      value: item.value,
                      bank: item.receiverBank,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={2} />
      <ReactModal
        isOpen={isOpenModal}
        className={'pix-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        {!receipt ? (
          <>
            <div className="pix-modal-header">
              <h1>Dados do Favorecido</h1>
            </div>

            <div className="pix-modal-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{preReceit?.ReceiverName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {preReceit?.ReceiverBank} -{' '}
                    {
                      bankList.filter(
                        (item) => item.code == preReceit?.ReceiverBank
                      )[0]?.name
                    }{' '}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(preReceit?.ReceiverTaxNumber)}</p>
                </div>
              </div>

              <div className="col-md-7">
                {!mfaRequired ? (
                  <>
                    <label>Valor de Transferência</label>
                    <div className="input-area input-pix-currency">
                      <div className="input-area-icon col-md-1">
                        <TbCoin size={18} />
                      </div>
                      <div className="col-md-11">
                        <IntlCurrencyInput
                          currency="BRL"
                          config={currencyConfig}
                          onChange={handleChange}
                          value={valor}
                        />
                      </div>
                    </div>
                    {!alreadyInFavorites && (
                      <div className="d-flex gap-1 mt-2">
                        <input
                          type="checkbox"
                          id="favoritePix"
                          onChange={() => {
                            setisFavorite(!isFavorite);
                          }}
                        />
                        <label htmlFor="favoritePix">Salvar nos contatos</label>
                      </div>
                    )}
                    {errorMessage && (
                      <p className="errorMessage">{errorMessage}</p>
                    )}
                  </>
                ) : (
                  <>
                    <label>Código de confirmação</label>
                    <div className="input-area">
                      <div className="input-area-icon col-md-1">
                        <TbKey size={18} />
                      </div>
                      <div className="col-md-11">
                        <input
                          type="text"
                          placeholder="Insira o código MFA"
                          value={mfaCode}
                          onChange={(e) => {
                            setMfaCode(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                        <FilterItemMfaGeneral>
                          {isDisabled && (
                            <p>
                              Você pode solicitar um novo código em 0:
                              {countdown} segundos
                            </p>
                          )}
                          <button onClick={handleClick} disabled={isDisabled}>
                            Novo Código
                          </button>
                        </FilterItemMfaGeneral>
                    {errorMessage && (
                      <p className="errorMessage">{errorMessage}</p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="pix-modal-footer">
              <button className="cancel-button" onClick={closeModal}> Cancelar </button>
              <button className="confirm-button" onClick={sendMfa} hidden={mfaRequired}> Confirmar Pix </button>
              <button className="confirm-button" onClick={sendPix} hidden={!mfaRequired}>
                {sendPixLoader ? 
                  <BeatLoader color="var(--text-primary)" loading={sendPixLoader} css={override} size={20} />
                 : 
                 'Confirmar código'
                }
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="pix-modal-header">
              <TbCircleCheck color="#32D16D" size={75} />
              <h1>Transferência Pix realizada com sucesso!</h1>
            </div>

            <div>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>

            <div className="pix-modal-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{response?.toName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {response?.toBank} -{' '}
                    {
                      bankList.filter(
                        (item) => item.code == response?.toBank
                      )[0]?.name
                    }
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(response?.toTaxNumber)}</p>
                </div>
              </div>
            </div>

            <div className="pix-modal-footer">
              <NavLink
                to="/comprovante"
                target="_blank"
                className="share-button"
              >
                Compartilhar comprovante
                <TbShare size={16} />
              </NavLink>
            </div>
          </>
        )}
      </ReactModal>
    </>
  );
}
